<template>
  <div>
    <load-profile />

    <b-row>
      <b-col
        md="6"
        lg="4"
        xl="3"
        v-for="(syncButton, idx) in syncButtons"
        :key="`sync-button-${idx}`"
      >
        <sync-button
          :title="$t(syncButton.title)"
          :action="syncButton.action"
          :fields="syncButton.fields ? syncButton.fields : fields"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BRow, BCol } from "bootstrap-vue";
import SyncButton from "./SyncButton.vue";
import FormInput from "./formInput";

export default {
  components: {
    BRow,
    BCol,

    SyncButton,
  },
  data() {
    return {
      syncButtons: [
        {
          title: "field.agent",
          action: "sync-agent",
          fields: [
            {
              key: "fromDate",
              label: "field.fromDate",
              rules: "required",
              type: "date-picker",
              disableType: "after",
              cols: 12,
            },
            {
              key: "no",
              label: "field.code",
              rules: "max:100",
              type: "text",
              cols: 12,
            },
          ],
        },
        {
          title: "field.outlet",
          action: "sync-outlet",
        },
        {
          title: "field.deletedCustomer",
          action: "sync-deleted-customer",
          fields: [
            {
              key: "fromDate",
              label: "field.fromDate",
              rules: "required",
              type: "date-picker",
              disableType: "after",
              cols: 12,
            },
          ],
        },
        {
          title: "field.product",
          action: "sync-product",
        },
        {
          title: "field.productStock",
          action: "sync-product-stock",
          fields: [
            {
              key: "agentId",
              label: "field.agent",
              type: "async-single-selection",
              operator: "in",
              repository: "agent",
              selectionKey: "id",
              selectionLabel: "agentCode",
              cols: 12,
            },
            {
              key: "no",
              label: "field.code",
              rules: "max:100",
              type: "text",
              cols: 12,
            },
          ],
        },
        {
          title: "field.deletedProduct",
          action: "sync-deleted-product",
          fields: [
            {
              key: "fromDate",
              label: "field.fromDate",
              rules: "required",
              type: "date-picker",
              disableType: "after",
              cols: 12,
            },
          ],
        },
        {
          title: "field.salesOrder",
          action: "sync-sales-order",
        },
        {
          title: "field.deletedSalesOrder",
          action: "sync-deleted-sales-order",
          fields: [
            {
              key: "fromDate",
              label: "field.fromDate",
              rules: "required",
              type: "date-picker",
              disableType: "after",
              cols: 12,
            },
          ],
        },
        {
          title: "field.salesInvoice",
          action: "sync-sales-invoice",
        },
        {
          title: "field.shipment",
          action: "sync-sales-shipment",
        },
        {
          title: "field.creditNote",
          action: "sync-sales-credit-memo",
        },
        {
          title: "field.transferReceipt",
          action: "sync-transfer-order-receipt",
        },
        {
          title: "field.transferShipment",
          action: "sync-transfer-order-shipment",
        },
        {
          title: "field.postTransferReceipt",
          action: "sync-transfer-receipt",
        },
        {
          title: "field.postTransferShipment",
          action: "sync-transfer-shipment",
        },
        {
          title: "field.purchaseInvoice",
          action: "sync-purchase-invoice",
        },
        {
          title: "field.postPurchaseInvoice",
          action: "sync-post-purchase-invoice",
        },
        {
          title: "field.reasonCode",
          action: "sync-reason-code",
          fields: [
            {
              key: "fromDate",
              label: "field.fromDate",
              rules: "required",
              type: "date-picker",
              disableType: "after",
              cols: 12,
            },
          ],
        },
        {
          title: "field.salesReasonCode",
          action: "sync-sales-reason-code",
          fields: [
            {
              key: "fromDate",
              label: "field.fromDate",
              rules: "required",
              type: "date-picker",
              disableType: "after",
              cols: 12,
            },
          ],
        },
      ],
    };
  },
  setup() {
    const fields = [...FormInput];

    return {
      fields,
    };
  },
};
</script>
