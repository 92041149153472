export default [
  {
    key: 'fromDate',
    label: 'field.fromDate',
    rules: 'required',
    type: 'date-picker',
    disableType: 'after',
    cols: 12,
  },
  {
    key: 'agentId',
    label: 'field.agent',
    type: 'async-single-selection',
    operator: 'in',
    repository: 'agent',
    selectionKey: 'id',
    selectionLabel: 'agentCode',
    cols: 12,
  },
  {
    key: 'no',
    label: 'field.code',
    rules: 'max:100',
    type: 'text',
    cols: 12,
  },
]
