<template>
  <div>
    <n-button-loading
      type="button"
      variant="success"
      :loading="loading"
      class="mt-1"
      @click="show"
      block
    >
      {{ title }}
    </n-button-loading>
    <b-modal
      cancel-variant="outline-secondary"
      :cancel-title="$t('button.close')"
      :title="title"
      centered
      hide-footer
      no-close-on-backdrop
      size="lg"
      v-model="isShow"
    >
      <validation-observer ref="syncForm" #default="{ invalid }">
        <n-form-confirmation
          key="syncForm"
          @submit="submit"
          :form="$refs.syncForm"
          :disabled="invalid"
        >
          <n-input :fields="fields" v-model="data"></n-input>
          <b-row>
            <b-col cols="12" class="text-right">
              <b-button type="submit" variant="success">
                {{ $t("button.sync") }}
              </b-button>
            </b-col>
          </b-row>
        </n-form-confirmation>
      </validation-observer>
    </b-modal>
  </div>
</template>

<script>
import { BModal, BButton, BRow, BCol } from "bootstrap-vue";
import NButtonLoading from "@/components/NButtonLoading";
import NFormConfirmation from "@/components/NFormConfirmation";
import NInput from "@/components/NInput";
import moment from "moment";
import Repository from "@/repositories/RepositoryFactory";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

const SyncErpDataRepository = Repository.get("syncErpData");

export default {
  components: {
    BModal,
    BButton,
    BRow,
    BCol,

    NButtonLoading,
    NFormConfirmation,
    NInput,
  },
  props: {
    title: {
      type: String,
      default: "",
    },
    action: {
      type: String,
      default: "",
    },
    fields: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      key: 1,
      loading: false,
      isShow: false,
      data: {
        fromDate: moment().format("YYYY-MM-DD"),
      },
      syncReference: null,
    };
  },
  mounted() {
    this.$bus.$on("firestore-sycn", (object) => {
      if (
        object?.syncReference &&
        object?.syncReference == this.syncReference
      ) {
        if (object?.success) {
          this.$hideSyncing();
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            autoHideDelay: 2000,
            props: {
              title: this.$t("alert.syncSuccess"),
              iconType: "line-awesome",
              icon: "las la-cloud-download-alt",
              variant: "light-success",
              textVariant: "success",
            },
          });
        } else {
          this.$hideSyncing();
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            autoHideDelay: 2000,
            props: {
              title: this.$t("alert.syncFail"),
              iconType: "line-awesome",
              icon: "las la-cloud-download-alt",
              variant: "light-danger",
              textVariant: "danger",
            },
          });
        }
      }
    });
  },
  beforeDestroy() {
    this.$bus.$off("firestore-sycn");
  },
  methods: {
    show() {
      if (!this.action) {
        return;
      }
      this.data = {
        fromDate: moment().format("YYYY-MM-DD"),
      };
      this.isShow = true;
    },
    hide() {
      this.isShow = false;
    },
    submit() {
      this.$refs.syncForm.validate().then((success) => {
        if (success) {
          this.$bvModal
            .msgBoxConfirm(
              this.$t("alert.syncData", {
                module: this.title.toLowerCase(),
                date: moment(this.data.fromDate)
                  .locale(this.$i18n.locale)
                  .format("LL"),
              }),
              {
                title: this.$t("alert.areYouSure"),
                size: "sm",
                buttonSize: "sm",
                okVariant: "success",
                okTitle: this.$t("button.sync"),
                cancelTitle: this.$t("button.cancel"),
                cancelVariant: "outline-secondary",
                hideHeaderClose: false,
                centered: true,
              }
            )
            .then((value) => {
              if (value) {
                this.save();
              }
            });
        }
      });
    },
    save() {
      this.syncReference = new Date().getTime();
      this.$showSyncing(this.title);
      this.loading = true;
      SyncErpDataRepository.sync(this.action, {
        ...this.data,
        backDaySync: moment().diff(moment(this.data.fromDate), "days"),
        syncReference: this.syncReference,
      })
        .then(() => {
          this.loading = false;
          this.hide();
        })
        .catch(() => {
          this.loading = false;
          this.$hideSyncing();
        });
    },
  },
};
</script>
